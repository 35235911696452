import React from "react";
import { Typewriter } from "react-simple-typewriter";
import { useMediaQuery } from "usehooks-ts";
import { motion, useInView, useAnimation } from "framer-motion";
import videoBg from "./images/slide-about-int-fcf4926769525e67d8ee10833898e8dc.webm";
import Awards from "../../../components/details/Awards";
import "./index.css";

const Details = () => {
  const winHeight = window.innerHeight;
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div id="details">
      <div className="videoContainer">
        <video className="video" autoPlay muted loop>
          <source src={videoBg} type="video/webm" />
        </video>
      </div>

      <div className="subDiv">
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 1, delay: 0.25 }}
        >
          <p className="title">DEVELOPING THE FUTURE.</p>
          <p className="titleName">WORLDWIDE.</p>
        </motion.div>
        <div className="subTitleContainer">
          <motion.span
            className="subTitle"
            variants={{
              hidden: { opacity: -2, y: 75 },
              visible: { opacity: 1.5, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1, delay: 0.25 }}
          >
            <Typewriter
              words={[
                "Elevate Your Online Presence with CosmoLink - Your App & Web",
                "Development Partner.",
              ]}
              loop={true}
              cursor
              cursorStyle="|"
              cursorColor="white"
              typeSpeed={10}
              deleteSpeed={30}
              delaySpeed={1500}
            />
          </motion.span>
        </div>
        <div className="awardsContainer">
          <Awards />
        </div>
      </div>
    </div>
  );
};

export default Details;
