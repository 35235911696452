import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Granim from "granim";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/index";
import { FaGooglePlay, FaApple, FaGlobe } from "react-icons/fa";

import { projects } from "../portfolio/projects";
import SeeMore from "../../components/seeMore";

const ProjectDetail = () => {
  const [hideNavbar, setHideNavbar] = useState(false);
  const [sectionVisible, setSectionVisible] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const { id } = useParams();
  const projectDetails = projects.find((item) => item.id == id);
  console.log(projectDetails);

  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=your_app_package_name";
  const appStoreUrl = "https://apps.apple.com/app/your_app_id";

  return (
    <div>
      <Navbar
        hideNavbar={hideNavbar && isMobile}
        sectionVisible={sectionVisible}
        backgroundColor={projectDetails.backgroundColor}
      />
      <section id="projectCardDetails">
        <div
          style={{ backgroundColor: projectDetails.bgColor }}
          className="min-h-screen mt-20 sm:mt-24 pt-10 "
        >
          <div className="flex flex-col-reverse sm:flex-row justify-center items-center text-center ">
            <div className="px-4 sm:px-12 pt-12 sm:pt-2">
              <span className=" font-poppins font-bold text-white text-5xl leading-[60px] sm:text-5xl md:text-7xl ">
                {projectDetails.title}
              </span>
              <p className="w-full text-white font-text font-bold text-base sm:text-sm md:text-sm lg:text-lg mt-7 md:mt-16">
                {projectDetails.description}
              </p>
            </div>
            <div className="">
              <img
                src={projectDetails.screenShot}
                className="sm:w-[100%] h-full object-cover"
              />
              <div className="flex flex-row justify-center items-center">
                {projectDetails.linkGoogle && (
                  <a
                    href={projectDetails.linkGoogle}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-5"
                  >
                    <FaGooglePlay size={32} style={{ color: "#fff" }} />
                  </a>
                )}
                {projectDetails.appleLink && (
                  <a
                    href={projectDetails.appleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-5"
                  >
                    <FaApple size={32} style={{ color: "#fff" }} />
                  </a>
                )}
                {projectDetails.web && (
                  <a
                    href={projectDetails.web}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGlobe size={32} style={{ color: "#fff" }} />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="bg-red-200 w-[100%] m-auto">
            <img
              src={projectDetails.images}
              className="w-[100%] h-[90%] object-cover mt-8 sm:mt-24 lg:mt-32 items-center "
            />
          </div>
        </div>
        <div className="bg-gray-100 py-16">
          <div className="mx-auto">
            <h2 className="text-3xl font-semibold text-center mb-8">
              What Client Are Saying
            </h2>
            <div className="flex justify-center items-center">
              <div
                key={projectDetails.id}
                className="w-[70%] p-6 rounded-md shadow-md"
              >
                <p className="text-gray-700 mb-4 text-center">
                  {projectDetails.comment}
                </p>
                <div className="flex gap-10 items-center justify-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full object-cover"
                      src={projectDetails.profile}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-gray-800 font-semibold">
                      {projectDetails.name}
                    </p>
                    <p className="text-gray-600">{projectDetails.role}</p>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <div className="flex flex-shrink-0 m-auto">
                    {Array.from(
                      { length: projectDetails.rating },
                      (_, index) => (
                        <svg
                          key={index}
                          className="h-5 w-5 text-yellow-500 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 0l2.4 6.8h6L12.4 10l1.6 4.8H10L8 20l-2.4-6.4H0L5.6 10 0 3.2h6z" />
                        </svg>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SeeMore id={id} />
        <Footer backgroundColor={projectDetails.backgroundColor} />
      </section>
    </div>
  );
};

export default ProjectDetail;
