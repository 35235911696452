import React, { useState } from "react";
import { CgMenu } from "react-icons/cg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import logo from "./image/logo.png";
import "./index.css";

function Navbar({ hideNavbar = false, sectionVisible, backgroundColor }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isTab = useMediaQuery("(max-width: 768px)");

  const links = [
    {
      name: "About",
      path: "/",
    },
    {
      name: "Projects",
      path: "/portfolio",
    },
    // {
    //   name: "Service",
    //   path: "/service",
    // },
    {
      name: "Contact",
      path: "/contact",
    },
  ];

  const onNavItemClick = (path) => {
    navigate(path);
    setIsOpen(false);
  };
  console.log("b", backgroundColor);

  const location = useLocation();
  const { pathname } = location;

  return hideNavbar && !isOpen ? (
    <div id="toggleBtn" onClick={() => setIsOpen(true)}>
      <CgMenu />
    </div>
  ) : (
    <>
      <section
        id="navbar"
        style={{ backgroundColor: isOpen ? "black" : backgroundColor }}
      >
        <div className="subDiv">
          <Link to="/">
            <img src={logo} alt="" className="logo"></img>
          </Link>
          <div className="ul-Div">
            <div
              className="openMenu"
              onClick={() => setIsOpen(!isOpen)}
              style={isOpen && !isDesktop ? { color: "#fff" } : {}}
            >
              <CgMenu />
            </div>
            <ul
              style={
                isTab === true && !isOpen
                  ? { display: "none" }
                  : {
                      left: "0px",
                      marginTop: -10,
                    }
              }
              className="listWrapper"
            >
              {links.map((item, index) => (
                <li className="list" key={index}>
                  <Link
                    to={item.path}
                    onClick={() => onNavItemClick(item.path)}
                    style={pathname === item.path ? { color: "#83d6d5" } : {}}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Navbar;
