import React, { useState } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import ProjectDetail from "../../projectDetail";
import { FaGooglePlay, FaApple, FaGlobe } from "react-icons/fa";

export const projects = [
  {
    id: "chance",
    image:
      "https://images.unsplash.com/photo-1541021261549-c19a4f74be1a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Chance",
    logo: require("./images/chance.png"),
    screenShot: require("../../projectDetail/images/chanceImages.png"),
    description:
      "Chance is an intelligent personality-based, pseudonymous connecting and dating app that matches you with people based on personality and interests. You're more than just a photo. You have stories to tell, passions to share, and things to talk about that are more interesting than just the weather. Because you deserve what dating deserves, a real Chance!",

    images: require("../../projectDetail/images/chanceimage.png"),
    name: "Rajeet Parekh",
    profile: require("./images/chance.jpeg"),
    role: "Founder @ Chance",
    rating: 5,
    comment: "Delivered really good and quality work. Thanks!!",
    linkGoogle:
      "https://apps.apple.com/us/app/chance-personality-dating/id1638573081",
    appleLink:
      "https://play.google.com/store/apps/details?id=com.chanceapp.android&hl=en_US",
    web: "",
    backgroundColor: "#153d4a",
    bgColor: "#153d4a",
  },
  {
    id: "themorningcontext",
    image:
      "https://images.unsplash.com/photo-1551406483-3731d1997540?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "TMC",
    logo: require("./images/tmc.png"),
    screenShot: require("../../projectDetail/images/tsc.png"),
    description:
      "The Morning Context is a media and research company that tells the most ambitious, timely, deeply researched, and well-written stories. Paid for primarily by the readers. We publish more than 200 stories every year, that cover subjects ranging from startups, technology, and e-commerce, to fintech, retail, and education. Everything you need to stay well-informed.If you are not a paying subscriber, we have a rich archive of free stories for you to read.",
    images: require("../../projectDetail/images/tmc.png"),
    name: "Priya Bubna",
    profile: require("./images/Priya.jpg"),
    role: "CEO",
    rating: 5,
    comment:
      "Top-tier service! Team at cosmolink not only addressed all our app issues but also introduced innovative features, making it better than before. Highly satisfied!.",
    linkGoogle:
      "https://play.google.com/store/apps/details?id=com.app.themorningcontext&hl=en_IN&gl=US",
    appleLink: "https://apps.apple.com/in/app/the-morning-context/id1551672701",
    web: "",
    backgroundColor: "#9f85ea",
    bgColor: "#9f85ea",
  },
  {
    id: "thehappyplace",
    image:
      "https://images.unsplash.com/photo-1600096194534-95cf5ece04cf?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "TheHappyPlace",
    logo: require("./images/happy.png"),
    screenShot: require("../../projectDetail/images/thp.png"),
    description:
      "The Happy Place is your one-stop solution whenever you want a smile on your face. Duh! When don't you? :stuck_out_tongue: All you have to do, is swipe! Swipe through endless funny videos, memes, GIFs, photos, audios, this list can go on… but every minute is another 60 seconds you’ve wasted without a smile on your smile.",
    images: require("../../projectDetail/images/thpimages.png"),
    name: "Rajeet Parekh",
    profile: require("./images/chance.jpeg"),
    role: "CEO",
    rating: 5,
    comment:
      "He did the job really well. Super talented and fast. Will work with him again. Thanks",
    linkGoogle: "",
    appleLink: "https://apps.apple.com/in/app/the-happy-place/id1543197310",
    web: "",
    backgroundColor: "#f87587",
    bgColor: "#f87587",
  },
  {
    id: "weconnect",
    image:
      "https://plus.unsplash.com/premium_photo-1661764559869-f6052a14b4c9?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "WeConnect",
    logo: require("./images/weconnect.png"),
    screenShot: require("../../projectDetail/images/weconnect.png"),
    description:
      "We help companies connect with their website audience using conversational experiences combining chatbot, live messaging, video chat, booked meetings, and much more.",
    images: require("../../projectDetail/images/weconnectimages.png"),
    name: "Yogesh",
    profile: require("./images/yogesh.png"),
    role: "Product & Recruitmen",
    rating: 5,
    comment:
      "Working with cosmolink was an absolute pleasure! They developed our app with precision and attention to detail. The final product exceeded our expectations. Highly recommend!",
    linkGoogle:
      "https://play.google.com/store/apps/details?id=com.weconnect.android",
    appleLink: "https://apps.apple.com/us/app/weconnect-chat/id6443603402",
    web: "",
    backgroundColor: "#0d9488",
    bgColor: "#0d9488",
  },
  {
    id: "tickeTini",
    image:
      "https://images.unsplash.com/photo-1619229664787-474a3ddf185d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "TickeTini",
    screenShot: require("../../projectDetail/images/ticket.png"),
    logo: require("./images/ticket.png"),
    description:
      "TickeTini is a comprehensive online platform that enables users to discover, explore, and book tickets for a wide range of entertainment events. With its user-friendly interface, extensive event database, personalized recommendations, and secure ticketing services, TickeTini continues to be the preferred choice for entertainment enthusiasts seeking seamless and memorable experiences.",
    images: require("../../projectDetail/images/ticketimages.png"),
    name: "Rajeet Parekh",
    profile: require("./images/chance.jpeg"),
    role: "CEO",
    rating: 5,
    comment:
      "Thanks to cosmolink, our app idea turned into a reality! Their expertise in app development is unmatched. We're impressed by their dedication to quality and customer satisfaction. Thanks.",
    linkGoogle: "",
    appleLink: "",
    web: "https://tickettini-377814.web.app/",
    backgroundColor: "#343545",
    bgColor: "#343545",
  },
  {
    id: "babysense",
    image:
      "https://images.unsplash.com/photo-1501686637-b7aa9c48a882?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "BabySense",
    logo: require("./images/babyLogo.jpeg"),
    screenShot: require("../../projectDetail/images/babysence.png"),
    description:
      "A smart way to monitor and keep your baby safe - Babysense Baby Smart Nursery Monitoring solution provides a modular eco-system allowing you to track breathing movements & sleep patterns, capture priceless moments and develop healthy sleep habits for the entire family.",
    images: require("../../projectDetail/images/babysenceimage.png"),
    name: "Itsik B.",
    profile: require("./images/Itsik.jpeg"),
    role: "CTO",
    rating: 5,
    comment:
      "He did the job really well. Super talented and fast. Will work with him again. Thanks",
    linkGoogle:
      "https://play.google.com/store/apps/details?id=com.hisense.nursery&hl=en&gl=US",
    appleLink: "https://apps.apple.com/il/app/babysense-connect/id1530194098",
    web: "",
    backgroundColor: "#0c4a6f",
    bgColor: "#0c4a6f",
  },
  {
    id: "roof66",
    image:
      "https://images.unsplash.com/photo-1508167290553-87a67eca5bf5?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Roof66",
    logo: require("./images/logoRoof66.jpg"),
    screenShot: require("../../projectDetail/images/roof66.png"),
    description:
      "Located in Oklahoma City, Oklahoma, we serve the OKC metro area and beyond. Roof66 is passionate about providing quality construction services and a superior customer experience.",
    images: require("../../projectDetail/images/roof66images.png"),
    name: "David Biechler",
    profile: require("./images/devid.jpeg"),
    role: "",
    rating: 5,
    comment:
      "Incredible team at cosmolink! They built our website from scratch and delivered exactly what we wanted. Professional, responsive, and talented. Couldn't be happier!",
    linkGoogle: "",
    appleLink: "",
    web: "https://roof66.net/",
    backgroundColor: "#2d4072",
    bgColor: "#2d4072",
  },
  {
    id: "tutoro",
    image:
      "https://images.unsplash.com/photo-1604872441539-ef1db9b25f92?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Tutoro",
    logo: require("./images/logoTutoro.webp"),
    screenShot: require("../../projectDetail/images/tutoro.png"),
    description:
      "Tutoro Smart is the ultimate career counseling app for NEET and JEE students.- We offer personalized career counseling sessions, helping NEET and JEE aspirants make informed decisions about their future. - Our experienced counselors will guide you through the process, offering expert advice, assessing your strengths and interests, and recommending suitable career paths.",
    images: require("../../projectDetail/images/tutoroimages.png"),
    name: "Vibhor Katariya",
    profile: require("./images/tutoroprofile.jpg"),
    role: "Founder",
    rating: 5,
    comment:
      "Choosing cosmolink for our app development needs was the best decision we made! They were with us every step of the way, offering valuable insights and delivering a top-notch product.",
    linkGoogle:
      "https://play.google.com/store/apps/details?id=com.tutoro&hl=en_IN&gl=US",
    appleLink: "http://apps.apple.com/us/app/grocerspod/id1548327687",
    web: "",
    backgroundColor: "#858585",
    bgColor: "#858585",
  },
  {
    id: "travokarma",
    image:
      "https://images.unsplash.com/photo-1576737064520-f45d313d17ff?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8",
    title: "TravoKarma",
    logo: require("./images/logoTrova.webp"),
    screenShot: require("../../projectDetail/images/travo.png"),
    description:
      "Travokarma is built for travelers and by travelers to engage the traveling community in a fun way-Create activities and experiences With our easy step by step process, create activities and experiences quickly and reach potential travelers to participate, contribute and more",
    images: require("../../projectDetail/images/travoimages.png"),
    name: "Avadesh B",
    profile: require("./images/travoProfile.jpg"),
    role: "CEO",
    rating: 5,
    comment:
      "Highly impressed by the professionalism and expertise of cosmolink. Their app development team went above and beyond to ensure our app not only met but exceeded our expectations.",
    linkGoogle: "https://play.google.com/store/apps/details?id=com.travokarma",
    appleLink: "https://apps.apple.com/in/app/travokarma/id1596474556",
    web: "",
    backgroundColor: "#858585",
    bgColor: "#858585",
  },
  // {
  //   id: "grocerspod",
  //   image:
  //     "https://images.unsplash.com/photo-1664455340023-214c33a9d0bd?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //   title: "GrocersPod",
  //   logo: require("./images/logoGrosocers.jpeg"),
  //   screenShot: require("../../projectDetail/images/tutoro.png"),
  //   description:
  //     "Grocerspod is a virtual marketplace and delivery service for groceries and dorm essentials. It offers a variety of products, including:Daily essentials Meat and seafood Indian groceries, Household essentials, Fresh produce, Market basket orders.",
  //   images: require("../../projectDetail/images/chance.png"),
  //   name: "Mahabir Prasad D.",
  //   profile: require("./images/grosersPod.jpeg"),
  //   role: "CEO | Founder @iWebServices",
  //   rating: 5,
  //   comment:
  //     "He did the job really well. Super talented and fast. Will work with him again. Thanks",
  //   linkGoogle:
  //     "https://apps.apple.com/us/app/chance-personality-dating/id1638573081",
  //   appleLink:
  //     "https://play.google.com/store/apps/details?id=com.chanceapp.android&hl=en_US",
  //   web: "",
  //   backgroundColor: "#0d9488",
  //   bgColor: "#0d9488",
  // },
  // {
  //   id: "ichild",
  //   image:
  //     "https://plus.unsplash.com/premium_photo-1664301947931-f25c847609cc?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //   title: "iChild",
  //   logo: require("./images/logoIchild.webp"),
  //   screenShot: require("../../projectDetail/images/tutoro.png"),
  //   description:
  //     "iChild App brings together the educational centre and her students, and their parents allowing them to interact in a secure and safe social platform. She allows the centre to manage her students via a build in CRM system and shared updates such as events photos, videos, progress reports etc with students and parents exclusively.",
  //   images: require("../../projectDetail/images/chance.png"),
  //   name: "Shoaib Irshad",
  //   profile: require("./images/shoaib.jpg"),
  //   role: "Tech Manager",
  //   rating: 5,
  //   comment:
  //     "Tushar is a great react native developer, I would highly recommend him due to his skills, commitment to the job and responsible attitude.",
  //   linkGoogle: "https://play.google.com/store/apps/details?id=com.sg.IChild",
  //   appleLink: "",
  //   web: "",
  //   backgroundColor: "#0d9488",
  //   bgColor: "#0d9488",
  // },
  {
    id: "lnbconstruction",
    image:
      "https://images.unsplash.com/photo-1593313637552-29c2c0dacd35?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "LNBConstruction",
    logo: require("./images/lnb.png"),
    screenShot: require("../../projectDetail/images/construction.png"),
    description:
      "Whatever your project is, we have the expertise to do it through our various contracting modes including general contracting/bid-build, CM/GC, design-build, design-bid-build, and public-private partnerships.",
    images: require("../../projectDetail/images/constructionimages.png"),
    name: "Ashok",
    profile: require("./images/ashok.jpg"),
    role: "CEO | Founder",
    rating: 5,
    comment:
      "Working with [Your Company Name] was a seamless experience! Their team of developers is incredibly talented and communicative. They delivered our web project on time and within budget.",
    linkGoogle: "",
    appleLink: "",
    web: "https://lnbconstruction.in/",
    backgroundColor: "#7d8aa0",
    bgColor: "#7d8aa0",
  },
];

const ProjectCard = () => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  return (
    <section id="projectCard" className="bg-black">
      <div className="flex min-h-screen items-center justify-center bg-black mt-24">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
          {projects.map((project, index) => (
            <div
              key={index}
              style={{ height: "420px" }}
              className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30"
            >
              <div className="h-full w-full">
                <img
                  className="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                  src={project.image}
                  alt=""
                />
              </div>
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
              <div className="absolute inset-0 flex translate-y-[30%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                <img
                  className=" w-20 h-20 object-cover transition-transform duration-500 group-hover:rotate-0 group-hover:scale-110"
                  src={project.logo}
                  alt=""
                />
                <h1 className="font-dmserif text-3xl font-bold text-white opacity-100 transition-opacity duration-300 group-hover:opacity-0">
                  {project.title}
                </h1>
                <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  {showFullDescription
                    ? project.description
                    : `${project.description.slice(0, 100)}...`}
                </p>
                <Link to={`/projectDetail/${project.id}`}>
                  <button className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">
                    See More
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectCard;
