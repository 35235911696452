import React, { useEffect, useState } from "react";
import {
  FaReact,
  FaHtml5,
  FaCss3,
  FaBootstrap,
  FaNodeJs,
  FaPhp,
  FaPython,
} from "react-icons/fa"; // Import icons you need
import { MdJavascript } from "react-icons/md";
import { SiTailwindcss, SiMongodb, SiMysql } from "react-icons/si";
import "./index.css";
import { BsFillPatchCheckFill } from "react-icons/bs";

const experiences = [
  {
    category: "Frontend Development",
    details: [
      { skill: "HTML", level: "Experienced" },
      { skill: "CSS", level: "Experienced" },
      { skill: "JavaScript", level: "Experienced" },
      { skill: "Boostrap", level: "Experienced" },
      { skill: "Tailwind", level: "Experienced" },
      { skill: "React", level: "Experienced" },
    ],
  },
  {
    category: "Backend Development",
    details: [
      { skill: "Node Js", level: "Experienced" },
      { skill: "PHP", level: "Experienced" },
      { skill: "MongoDB", level: "Experienced" },
      { skill: "MYSQL", level: "Experienced" },
      { skill: "Python", level: "Experienced" },
      { skill: "Meta", level: "Experienced" },
    ],
  },
];

const colors = [
  "#e74c3c",
  "#3498db",
  "#f1c40f",
  "#2ecc71",
  "#9b59b6",
  "#34495e",
  "#e67e22",
  "#1abc9c",
]; // Add more colors as needed

const iconComponents = [
  FaCss3,
  FaHtml5,
  FaReact,
  FaBootstrap,
  SiTailwindcss,
  MdJavascript,
  FaNodeJs,
  SiMongodb,
  FaPhp,
  SiMysql,
  FaPython,
  // Add more as you like
];
const FloatingIcon = ({
  IconComponent,
  size,
  duration,
  delay,
  left,
  color,
  right,
}) => {
  const style = {
    fontSize: size,
    color: color, // Apply the color
    animationDuration: `${duration}s`,
    animationDelay: ` ${delay}s`,
    left: `${left}%`,
  };

  return (
    <div className="floating-icon" style={style}>
      <IconComponent />
    </div>
  );
};

const Experience = ({ count = 20 }) => {
  const [floatingIcons, setFloatingIcons] = useState([]);

  useEffect(() => {
    setFloatingIcons(
      Array.from({ length: count }).map((_, index) => ({
        IconComponent:
          iconComponents[Math.floor(Math.random() * iconComponents.length)],
        color: colors[Math.floor(Math.random() * colors.length)], // Randomly pick a color
        size: `${Math.random() * 3 + 1.5}rem`, // Random size between 0.5rem and 1.5rem
        duration: Math.random() * 7 + 7, // Random animation duration between 5s and 10s
        delay: Math.random() * 0, // Random initial delay to stagger the icons
        left: Math.random() * 100, // Random horizontal position
      }))
    );
  }, [count]);
  return (
    <section id="experience" className="bg-blue-950">
      <div className="floating-icons-container flex flex-col justify-center items-center">
        <h2 className="text-center text-[30px] font-poppins font-bold text-cyan-500 lg:mt-[-4rem] md:my-[2rem] sm:my-[2rem] my-[4px]">
          Services
        </h2>
        {floatingIcons.map((props, index) => (
          <FloatingIcon key={index} {...props} />
        ))}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center lg:h-[50%] justify-center ">
          {experiences.map((experience, index) => (
            <div
              key={index}
              className="bg-sky-600 p-2 md:p-5 md:h-[100%] w-[80%] lg:w-[60%] md:w-[90%] sm:w-[80%] rounded-md shadow-md mx-auto  "
            >
              <h3 className="md:text-xl text-lg font-text font-bold mb-4 text-center text-white">
                {experience.category}
              </h3>
              <div className="flex flex-wrap items-center justify-center">
                {experience.details.map((detail, detailIndex) => (
                  <article
                    key={detailIndex}
                    className="flex items-center md:mb-6 mb-2 md:mr-4 mr-2 w-[100px] md:w-[120px]"
                  >
                    <BsFillPatchCheckFill className="text-white size-3 sm:size-4" />
                    <div className="text-center ml-2">
                      {/* Adjust margin here */}
                      <div>
                        <h4 className="font-text md:text-lg text-sm font-semibold text-white">
                          {detail.skill}
                        </h4>
                        <small className="text-white font-text text-xs ">
                          {detail.level}
                        </small>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;
