import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Parallax } from "react-parallax";

import { PROJECTDATA } from "../../../constants";

import avatar from "./images/tmc.png";
import { IoIosStar } from "react-icons/io";
import chance from "./images/chance.png";
import tmc from "./images/tmc.png";
import baby from "./images/babysence.png";
import { motion, useInView, useAnimation } from "framer-motion";
import "./index.css";

const projectsData = [
  {
    id: "themorningcontext",
    title: "The Morning Context",
    description:
      "The Morning Context is a media and research company that tells the most ambitious, timely, deeply researched, and well-written stories.",
    backgroundColor: "#9F84E3",
    imageSrc: require("./images/tmc.png"),
    name: "Priya Bubna",
    profile: require("../../portfolio/projects/images/Priya.jpg"),
    role: "CEO",
    comment:
      "Team at cosmolink not only addressed all our app issues but also introduced innovative features, making it better than before. Highly satisfied!",
  },
  {
    id: "chance",
    title: "Chance",
    description:
      "Chance is an intelligent personality-based, pseudonymous connecting and dating app that matches you with people based on personality and interests.",
    backgroundColor: "#1e7e93",
    imageSrc: require("./images/chance.png"),
    name: "Rajeet Parekh",
    profile: require("../../portfolio/projects/images/chance.jpeg"),
    role: "Founder @ Chance",
    rating: 5,
    comment: "Delivered really good and quality work. Thanks!!",
  },
  {
    id: "babysense",
    title: "Babysense",
    description:
      "A smart way to monitor and keep your baby safe - Babysense Baby Smart Nursery Monitoring solution provides a modular eco-system allowing you to track breathing movements & sleep patterns, capture priceless moments and develop healthy sleep habits for the entire family.",
    backgroundColor: "#fdadaa",
    imageSrc: require("./images/babysence.png"),
    name: "Itsik B.",
    profile: require("../../portfolio/projects/images/Itsik.jpeg"),
    role: "CTO",
    rating: 5,
    comment:
      "He did the job really well. Super talented and fast. Will work with him again. Thanks",
  },
];

const Projects = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.2 });

  useEffect(() => {
    console.log("Component mounted, scrolling to top");
    window.scrollTo(0, 0); // Scroll to the top when component is mounted
  }, []); // Run only on component mount

  return (
    <section id="section-1" class="projects-featured-outer ">
      <div class="projects-featured" data-parallax>
        <div class="projects-featured__item">
          <div class="projects-featured__item-inner ">
            <div class="projects-featured__item-info-2 ">
              <div
                id="services"
                className="sm:flex sm:justify-center bg-white h-screen w-full relative"
              >
                <div className="text-center sm:text-left mx-auto">
                  <h2 className="block pt-4 sm:px-3 md:px-4 sm:pt-7 md:pt-4 lg:px-6 lg:p-2 text-black font-semibold">
                    <span className="inline-block overflow-hidden text-black">
                      <span className="font-poppins font-bold text-6xl leading-[60px] sm:text-7xl md:text-8xl lg:text-9xl">
                        INNOVATIVE APP AND
                      </span>
                    </span>
                    <span className="inline-block overflow-hidden text-black">
                      <span className="font-poppins font-bold text-6xl leading-[60px] sm:text-7xl md:text-8xl lg:text-9xl">
                        WEB SOLUTIONS
                      </span>
                    </span>
                  </h2>
                  <motion.div
                    ref={ref}
                    variants={{
                      hidden: { opacity: 0, y: 70 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    transition={{
                      staggerChildren: 0.1,
                      duration: 0.5,
                      delay: 0.5,
                    }}
                    className="md:flex md:items-center mt-10 sm:mt-20 md:h-[100px] sm:px-4 sm:p-1 lg:px-6 lg:p-2"
                  >
                    <p className="text-2xl sm:text-2xl lg:text-4xl leading-120 text-black md:mr-20 mb-10 md:mb-0">
                      THOUGHTFULLY <br /> CRAFTED WEBSITES & APPS
                    </p>
                    <p className="text-2xl sm:text-2xl lg:text-4xl leading-120 text-black">
                      BUILT FOR <br />
                      THE LONG HAUL
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {projectsData.map((project, index) => (
            <div
              key={index}
              className="projects-featured__item"
              style={{ backgroundColor: project.backgroundColor }}
            >
              <div className="projects-featured__item-inner">
                <div className="projects-featured__item-info">
                  <Link to={`/projectDetail/${project.id}`}>
                    <div className="titleWrapper">
                      <h3 className="title">{project.title}</h3>
                      <p className="description">{project.description}</p>
                    </div>
                    <div className="user">
                      <img src={project.profile} alt="" />
                      <p>{project.comment}</p>
                      <div className="nameIcon">
                        <h3>{project.name}</h3>
                        <div style={{ display: "flex" }}>
                          <IoIosStar size={14} color="yellow" />
                          <IoIosStar size={14} color="yellow" />
                          <IoIosStar size={14} color="yellow" />
                          <IoIosStar size={14} color="yellow" />
                          <IoIosStar size={14} color="yellow" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="projects-featured__item-img">
                  <a data-cursor="view">
                    <img
                      width="1900"
                      height="1100"
                      src={project.imageSrc}
                      className="attachment-Section Huge size-Section Huge wp-post-image"
                      fetchpriority="high"
                      sizes="(max-width: 1900px) 90vw, 1900px"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
