import React, { useState } from "react";
import { Link } from "react-router-dom";
import { projects } from "../../screens/portfolio/projects";
import "./index.css";

function SeeMore({ id }) {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section id="seeMoreProjects" className="py-8 bg-gray-100 ">
      <div className="mx-auto flex flex-col justify-center items-center">
        <h2 className="text-4xl font-bold font-poppins mb-4">See Also</h2>
        <div className="relative flex items-center">
          <div className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth">
            {projects
              .filter((proj) => proj.id != id)
              .map((project, index) => (
                <div
                  key={index}
                  className="lg:w-[25%] md:w-[33.33%] sm:w-[50%] w-[75%]  h-[350px] inline-block p-2 hover::sclae group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30"
                >
                  <div className="h-full w-full">
                    <img
                      className="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                      src={project.image}
                      alt=""
                    />
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                  <div className="absolute inset-0 flex translate-y-[30%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                    <img
                      className=" w-20 h-20 object-cover transition-transform duration-500 group-hover:rotate-0 group-hover:scale-110"
                      src={project.logo}
                      alt=""
                    />
                    <h1 className="font-dmserif text-3xl font-bold text-white opacity-100 transition-opacity duration-300 group-hover:opacity-0">
                      {project.title}
                    </h1>
                    <p className="mb-3  whitespace-normal text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                      {showFullDescription
                        ? project.description
                        : `${project.description.slice(0, 100)}...`}
                    </p>
                    <Link to={`/projectDetail/${project.id}`}>
                      <button
                        onClick={scrollToTop}
                        className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60"
                      >
                        See More
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SeeMore;
