import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { useMediaQuery } from "usehooks-ts";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

import Email from "./img/email.png";
import Location from "./img/location.png";
import Phone from "./img/phone.png";

const contactInfo = [
  {
    icon: Location,
    text: "Manoram Business Center, Near Shyam Nagar Metro Station, Jaipur",
  },
  { icon: Email, text: "contact@cosmolink.com" },
  { icon: Phone, text: "+918949605284" },
];

const socialMediaLinks = [
  { icon: FaFacebook, href: "#" },
  { icon: FaTwitter, href: "#" },
  { icon: FaInstagram, href: "#" },
  {
    icon: FaLinkedin,
    href: "https://in.linkedin.com/company/cosmolink-technologies",
  },
];

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [details, setDetails] = useState("");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const sendEmail = (e) => {
    e.preventDefault();

    //your emailjs service id, template id, public id
    const serviceId = "service_ahfyvba";
    const templateId = "template_bzhdzhj";
    const publicId = "k7Mz7Z0VIw2q4_VlQ";

    // create new object that create dynamic template program
    if (!name || !phone || isNaN(phone) || !email || !details)
      return alert("Fill valid details");
    const templateParams = {
      from_name: name,
      from_email: email,
      name: name,
      phone: phone,
      email: email,
      details: details,
    };

    //send the email using emailjs
    emailjs
      .send(serviceId, templateId, templateParams, publicId)
      .then((response) => {
        console.log("email sent successfully!", response);
        setName("");
        setEmail("");
        setDetails("");
        setPhone("");
        alert("Request Raised!");
      })
      .catch((error) => {
        console.error("error sending email:", error);
      });
  };

  return (
    <div
      id="contact"
      className="bg-white flex items-center justify-center h-screen "
    >
      <div className=" mx-auto p-2 flex flex-col lg:flex-row items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-full md:h-[80%] lg:h-[80%] max-w-screen-lg bg-white rounded-2xl shadow-md overflow-hidden">
          {/* Contact Info */}
          <div className="p-3 md:p-8 lg:p-10">
            <h3 className="md:text-2xl text-xl font-poppins font-semibold text-black">
              Let's get in touch
            </h3>
            <p className="text-gray-500 font-text text-sm md:text-base lg:text-lg w-90% ">
              Welcome to Cosmolink, your ultimate destination for top-notch app
              and web development solutions. As a dynamic and innovative agency,
              we specialize in turning your visionary concepts into seamless
              digital experiences.
            </p>

            <div className=" md:mt-3 lg:mt-3 mt-1 md:space-y-2 lg:space-y-3">
              {contactInfo.map((item, index) => (
                <div key={index} className="flex items-center text-gray-600">
                  <img
                    src={item.icon}
                    className="md:w-5 md:h-5 w-3 h-3 lg:w-6 lg:h-6 mr-2 filter brightness-0 "
                    alt=""
                  />
                  <p className="font-text md:text-base text-sm ">{item.text}</p>
                </div>
              ))}
            </div>

            <div className="mt:2 lg:mt-4 flex flex-row md:flex-col ">
              <p className="text-gray-800 font-text md:text-xl text-base mt-1 md:mt-4">
                Connect with us :
              </p>
              <div className="flex space-x-2 mt-2">
                {socialMediaLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    className="text-black hover:text-gray-300"
                  >
                    <link.icon size={isMobile ? 18 : 25} />
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="relative bg-black  ">
            <span className="absolute bg-black w-6 h-6 top-8 left-[-12px] transform rotate-45"></span>
            <form className="relative z-10 overflow-hidden p-2 md:p-6 lg:p-8  sm:p-2 h-full">
              <h3 className="md:text-2xl text-xl font-poppins font-semibold text-white leading-[30px] mb-2">
                Contact us
              </h3>
              <div className="md:mb-3 sm:mb-2 mb-1">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="font-text p-[0.3rem] md:p-[0.4rem] rounded-full w-[90%] border-2 border-solid bg-black text-white placeholder:text-white"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="md:mb-3 sm:mb-2 mb-1">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="font-text p-[0.3rem] md:p-[0.4rem] rounded-full w-[90%] border-2 border-solid bg-black text-white placeholder:text-white"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="md:mb-3 sm:mb-2 mb-1">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  className="font-text p-[0.3rem] md:p-[0.4rem] rounded-full w-[90%] border-2 border-solid bg-black text-white placeholder:text-white"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="md:mb-3 sm:mb-2 mb-1">
                <textarea
                  name="message"
                  id="message"
                  className="font-text pl-2 pt-2 rounded-xl w-[90%] lg:h-40 md:h-20 border-2 border-solid bg-black text-white placeholder:text-white resize-none "
                  placeholder="Message"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                ></textarea>
              </div>
              <input
                type="submit"
                value="Send"
                className="btn bg-white font-text text-black font-bold px-4 py-1 md:px-5 md:py-2 rounded-full text-top"
                onClick={sendEmail}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
