import React, { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import Navbar from "../../components/navbar";
import Details from "./details";
import Services from "./services";
import Projects from "./projects";
import Experience from "./experiences";
import ContactUs from "./contactUs";
import Footer from "../../components/footer";

const Home = () => {
  const [hideNavbar, setHideNavbar] = useState(false);
  const [sectionVisible, setSectionVisible] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const winH = window.innerHeight;

  const handleScroll = () => {
    const position = window.scrollY;
    setSectionVisible(Math.round(position / winH));
    if (position == 0) setHideNavbar(false);
    else setHideNavbar(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div onScroll={handleScroll}>
      <Navbar
        hideNavbar={hideNavbar && isMobile}
        sectionVisible={sectionVisible}
      />
      <Details />
      {/* <Services /> */}
      <Projects />
      <Experience />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
