import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/home";
import Portfolio from "./screens/portfolio";
import ContactUs from "./screens/contact";
import ProjectDetail from "./screens/projectDetail";
import Splash from "./components/splash";
import "./App.css";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <BrowserRouter>
      {loading ? ( // Show splash screen while loading
        <Splash finishLoading={() => setLoading(false)} />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/projectDetail/:id" element={<ProjectDetail />} />
          <Route path="/:id" element={<ProjectDetail />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
