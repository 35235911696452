import React, { useState } from "react";
import { Image } from "granim";
import anime from "animejs";
import { useEffect } from "react";

import logo from "../navbar/image/logo.png";

function Splash({ finishLoading }) {
  const [isMounted, setIsMounted] = useState(false);

  const animate = () => {
    const loader = anime.timeline({
      complete: () => finishLoading(),
    });

    loader.add({
      targets: "#logo",
      delay: 0,
      scale: 1,
      duration: 500,
      easing: "easeInOutExpo",
    });
    loader.add({
      targets: "#logo",
      delay: 0,
      scale: 1.25,
      duration: 1500,
      easing: "easeInOutExpo",
    });
    loader.add({
      targets: "#logo",
      delay: 0,
      scale: 1,
      duration: 500,
      easing: "easeInOutExpo",
    });
    loader.add({
      targets: "#logo",
      delay: 0,
      scale: 1.25,
      duration: 1500,
      easing: "easeInOutExpo",
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 10);
    animate();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className="flex h-screen items-center justify-center bg-black"
      isMounted={isMounted}
    >
      <img width={160} height={160} id="logo" alt="" src={logo} />
    </div>
  );
}

export default Splash;
